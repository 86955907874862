<template>
  <section class="modal-mask-show" @click.self="$emit('close')">
    <div class="modal">
      <p class="modal__close" @click="$emit('close')"></p>
      <div></div>
      <p class="modal__title">Ваше обращение успешно отправлено!</p>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ModalAppeal'
}
</script>
<style lang="scss">
  .modal-mask-show {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }
  .modal{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    width: 26.813rem;
    height: 26.63rem;
    border-radius: .19rem;
    &__close {
        background:  #ffffff url('../../assets/icons/close.svg') center center no-repeat;
        background-size: .75rem;
        width: 2.38rem;
        height: 2.38rem;
        position: absolute;
        right: -0.8rem;
        top: -0.8rem;
        cursor: pointer;
        border-radius: 50%;
    }
    div{
        width: 5.75rem;
        height: 5.75rem;
        background: url('../../assets/icons/successfully_posted.svg') center center no-repeat;
        background-size: contain;
        margin-bottom: 2.09rem;
    }
    &__title{
        width: 13rem;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
  }
  @media screen and (max-width: 795px) {
  }
</style>
