<template>
    <div class="like__social">
        <div class="like__appeal">
            <div></div>
            <span>134</span>
            <p>Понравилось</p>
        </div>
        <div class="share-social">
            <p>Вы можете поделиться страницей обращения в соцсетях.</p>
            <a v-for="(social, key) in socialNetworks" :href="social.link" :key="`social${key}`">
                <span class="icon" :style="`background: url('${'/assets/icons/' + social.icon}') center / contain no-repeat`"></span>
            </a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'LikeAppeal',

  data () {
    return {
      socialNetworks: [
        // { name: 'facebook', link: 'https://www.facebook.com/sharer/sharer.php?u=&t=', icon: 'facebook.svg' },
        { name: 'vk', link: 'https://vkontakte.ru/share.php?url=&title=description=&image=', icon: 'vk.svg' },
        { name: 'twitter', link: 'https://twitter.com/home?status=', icon: 'twitter.svg' },
        { name: 'telegram', link: 'https://twitter.com/', icon: 'telegram.svg' }
      ]
    }
  },

  mounted () {
    //
  },

  methods: {
    //
  }

}
</script>

<style scoped lang="scss">
.like{
    &__social{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__appeal{
        display: flex;
        font-weight: 600;
        font-size: .813rem;
        line-height: 1.25rem;
        color: #5E686C;

        div{
            width: 1.31rem;
            height: 1.31rem;
            background: url('../../assets/icons/like.svg') center center no-repeat;
            background-size: contain;
        }
        span{
            margin: 0 .63rem 0 .44rem;
            color: #5A79A8;
        }
    }
}
  .share-social {
    display: flex;
    align-items: center;

    a {
      width: 2rem;
      height: 2rem;
      background: #EEF4FA;
      border-radius: 100%;
      position: relative;

      .icon {
        position: absolute;
        left: 22%;
        top: 22%;
        width: 54%;
        height: 54%;
      }
    }
    a:not(:last-of-type){
      margin-right: 0.9375rem;
    }
    p {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 154%;
      padding-right: 1.93rem;

      color: #5E686C;
    }
  }
  @media screen and (max-width: 420px){
    .share-social {
      flex-wrap: wrap;

      p {
        padding-right: 0;
        margin-top: 0.75rem;
      }
    }

  }
</style>
