<template>
    <section class="news">
            <!--  галлерея -->
        <div  class="news__stream">

            <!-- слайдей изображений -->
            <hooper @slide="updateCarousel" ref="carousel" class="slider-stream" :transition="400" :wheelControl='false'>
                <slide v-for="(item, i) in getImages" :key="`slider${i}`" class="slider-stream__item">
                    <span class="slider-stream__video" :style="`background: url('${'/assets/temp/' + item}') center / cover`"></span>
                </slide>
            </hooper>

            <!-- превью изображений -->
            <div class="preview">
                <p v-for="(item, i) in getImages" @click="changeSlider(i)" :class="{  'active-img': carouselData === i}" :key="`points${i}`" class="img"
                :style="`background: url('${'/assets/temp/' + item}') center / cover`"></p>
            </div>

            <!-- кнопки навигации -->
            <div class="slider-nav">
                <div class="slider-nav__arrows">
                    <svg width="40" height="40" @click.prevent="slidePrev" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.8" r="20" transform="matrix(-1 0 0 1 20 20)" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4159 23.6015C21.8065 23.992 21.8065 24.6252 21.4159 25.0157L21.3013 25.1304C20.9108 25.5209 20.2776 25.5209 19.8871 25.1304L16.0905 21.3338C16.082 21.3259 16.0737 21.3178 16.0654 21.3096L15.9508 21.1949C15.8522 21.0963 15.7785 20.9823 15.7297 20.8606C15.5844 20.4999 15.6579 20.0716 15.9502 19.7793L16.0649 19.6646C16.0775 19.652 16.0903 19.6398 16.1034 19.628L19.8876 15.8438C20.2781 15.4533 20.9113 15.4533 21.3018 15.8438L21.4165 15.9585C21.807 16.349 21.807 16.9822 21.4165 17.3727L18.3018 20.4874L21.4159 23.6015Z" fill="#246CB7"/>
                    </svg>
                    <svg width="40" height="40" @click.prevent="slideNext" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.8" cx="20" cy="20" r="20" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5841 23.6015C18.1935 23.992 18.1935 24.6252 18.5841 25.0157L18.6987 25.1304C19.0892 25.5209 19.7224 25.5209 20.1129 25.1304L23.9095 21.3338C23.918 21.3259 23.9263 21.3178 23.9346 21.3096L24.0492 21.1949C24.1478 21.0963 24.2215 20.9823 24.2703 20.8606C24.4156 20.4999 24.3421 20.0716 24.0498 19.7793L23.9351 19.6646C23.9225 19.652 23.9097 19.6398 23.8966 19.628L20.1124 15.8438C19.7219 15.4533 19.0887 15.4533 18.6982 15.8438L18.5835 15.9585C18.193 16.349 18.193 16.9822 18.5835 17.3727L21.6982 20.4874L18.5841 23.6015Z" fill="#246CB7"/>
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Hooper, Slide } from 'hooper'

export default {
  name: 'SlideAppeal',
  components: {
    Hooper,
    Slide
  },
  props: {
    getImages: {
      type: Array
    }
  },
  data () {
    return {
      carouselData: 0
    }
  },
  methods: {
    slidePrev () {
      this.$refs.carousel.slidePrev()
    },
    slideNext () {
      this.$refs.carousel.slideNext()
    },
    changeSlider (num) {
      this.$refs.carousel.slideTo(num)
    },
    updateCarousel (payload) {
      this.carouselData = payload.currentSlide
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  &-header {
    display: flex;
    margin-bottom: 1.18rem;

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      text-transform: uppercase;
      color: #003E78;
      opacity: 0.8;
    }
    &__date {
      font-size: 0.875rem;
      margin-left: 0.625rem;
    }
  }

  &-content__left-img {
    width: 15rem;
    height: 11.25rem;
    float: left;
    margin: 0 1.56rem 1.56rem 0;

    border-radius: 0.5rem;
  }

  &-content__right-img {
    float: right;
    margin: 0 0 1.56rem 1.56rem;
  }

  ::v-deep &-content__text{
    margin-bottom: 2.5rem;

    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  &-social {
      border-top: 1px solid #D5D6D9;
      padding-top: 2.5rem;
  }

  &-img {
    width: 100%;
    height: 27.5rem;
    border-radius: 0.5rem;

    margin-bottom: 2.5rem;
  }
  .news-content__gallery {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
    .slider-nav{
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;

    &__arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin: 0 auto;

        svg{
            width: 2.5rem;
            height: 2.5rem;

            cursor: pointer;
        }
    }
  }

}
.news__stream {
    // margin-top: 1.62rem;
    position: relative;
    margin-bottom: 2.75rem;
  }

  .slider-stream__item {
    display: flex;
  }

  .slider-stream__video {
    width: 55.8rem;
    height: 27.5rem;
    display: flex;
  }

.preview{
    display: flex;
    width: 100%;
    overflow-x: auto;

    .img {
        display: flex;
        min-width: 4.56rem;
        height: 3.31rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 1.25rem;

        background: #9CA0A7;
        opacity: 0.5;
        cursor: pointer;

        &:last-child{
            margin-right: auto;
        }
        &:first-child{
            margin-left: auto;
        }
        &.active-img{
            border: 0.2rem solid #8ABD1F;
            opacity: 1;
        }
    }
}

@media screen and (max-width: 768px){
    .news {

      &-img {
        width: 100%;
        height: 21.81rem;
      }

      .news-content__gallery {
        width: 100%;
      }

      .slider-stream__video {
        width: 45rem;
        height: 21.81rem;
      }

    }
  }
   @media screen and (max-width: 420px){
    .news {
      &-header {
        flex-wrap: wrap;
      }

      &-content__left-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 1.56rem 1.56rem 0;
      }

      &-content__right-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 0 1.56rem 1.56rem;
      }

      &-img {
        width: 100%;
        height: 12.5rem;
      }

      &-social {
        padding-top: 1.375rem;
      }

      .slider-stream__video {
        width: 18rem;
        height: 12.5rem;
      }

      .slider-nav{
        display: none;
      }

    }
  }
</style>

<style lang="scss">

  .slider-stream {
    overflow: hidden;
    width: 40.94rem;
    height: 100%;
    border-radius: 0.5rem;

    &.hooper {
        height: 100%;
    }

    .hooper-list {
        .hooper-track{
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }
    }
  }

  .hooper-sr-only {
    display: none;
  }
  @media screen and (max-width: 768px){
    .slider-stream {
      width: 45rem;
    }
  }
  @media screen and (max-width: 420px){
    .slider-stream {
      width: 18rem;
    }
  }
</style>
