<template>
    <div>
        <p class="title_section">Документы</p>
        <div class="partner-info" v-for="(item, key) of content" :key="key+'doc'">
            <div class="img_block"></div>
            <div class="info_block">
                <p>{{item.title}}</p>
                <a class="download-file" :href="item.url">Скачать ({{item.type}})  {{item.size}} Кб</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Documents',
  props: {
    content: {
      type: Array
    }
  }
}
</script>
<style lang="scss" scoped>
.title_section{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.56rem;
}

.partner-info{
    // width: 26.94rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.88rem;
}
.partner-info:last-child{
    margin-bottom: 3.56rem;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.info_block{
    flex: auto;
    display: flex;
    // justify-content: space-between;
    p{
        font-weight: 600;
        font-size: .813rem;
        line-height: 1.25rem;
        color: #1F3245;
        // width: 12.81rem;
        margin: 0 1.125rem 0 .69rem;
    }
}
.download-file{
    padding: 0 0 0 1.125rem;
    border-left: #5a79a87d .06rem solid;
    // height: .813rem;
    font-size: .813rem;
    line-height: 1.25rem;
    color: #246CB7;
}
@media screen and (max-width: 768px){
    .info_block{
        margin-left: 1.5rem;
        flex-direction: column;
        p{
            width: 100%;
        }
        .download-file {
            padding: 0;
            border-left: none;
            height: unset;
        }
    }
}
</style>
