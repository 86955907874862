<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <section class="white-container">

            <!-- заголовок новости -->
            <div class="title_heder_block">
                <h5>{{ getAppeal.title }}</h5>
                <span>№{{ getAppeal.number }}</span>
            </div>
            <div class="detail__status">
                <div class="detail__type">
                    <figure :style="`background: url('/assets/icons/small_icon/hcs_small.svg') center / contain no-repeat;`"></figure>
                    <!-- <figure :style="`background: url('${require('/assets/icons/small_icon/' + getAppeal.small_icon)}') center / contain no-repeat;`"></figure> -->
                    <p>{{getAppeal.topic.title}}</p>
                </div>
                <div class="detail__type">
                    <figure></figure>
                    <p>Публичное обращение</p>
                </div>
                <div class="detail__status__block">
                  <p>Подано 8 марта</p>
                  <span class="red_point"></span>
                  <div class="red-line"></div>
                  <div class="blue-line"></div>
                  <span class="blue_point"></span>
                  <p>Срок рассмотрения истекает 12 марта</p>
                </div>
            </div>
            <!--  -->
            <div class="content">
                <div class="content__info_appeap">
                  <slide-appeal :getImages="getImages" v-show="!getAppeal.photo"/>
                  <!-- <slide-appeal :getImages="getImages" v-show="getAppeal.photo.length !== 0"/> -->
                    <h6>Описание</h6>
                    <p class="content__description"> {{sliceText(getAppeal.body)}} <span @click="clickText" v-if="!showText && hiddenText">  Читать полностью </span> <span @click="clickText" v-else-if="showText && hiddenText"> Скрыть </span></p>
                    <!--  -->
                    <attached-documents :content="getAppeal.documents" v-show="getAppeal.documents.length !== 0"/>
                    <div class="content__location">
                      <h6>Местоположение</h6>
                      <p>{{getAppeal.location}}</p>
                    </div>
                    <div class="map">
                        <map-form />
                    </div>
                    <h6>Участники</h6>
                    <div class="content__participant">
                        <figure :style="`background: url('${'/assets/temp/' + getAppeal.avatar}') center / contain no-repeat;`"></figure>
                        <div>
                            <span>Инициатор</span>
                            <p>{{getAppeal.name}}</p>
                        </div>
                    </div>
                </div>
                <div class="content__status">
                    <div class="content__status_history">
                        <figure>
                            <p></p>
                        </figure>
                        <div>
                          <p class="status_date">Сегодня в 12:03</p>
                          <p class="status_title">Ваше ображение отправлено на модерацию</p>
                          <p class="status_subtitle">Обращение <span>№{{ getAppeal.number }}</span> зарегистрирована в системе и отправлена на модерацию.</p>
                          <button>Отменить</button>
                        </div>
                    </div>
                    <div class="content__status_history">
                        <figure>
                            <p></p>
                        </figure>
                        <div>
                          <p class="status_date">Сегодня в 12:03</p>
                          <p class="status_title">Обращение зарегистрировано на сайте</p>
                          <p class="status_subtitle">Обращение <span>№{{ getAppeal.number }}</span> зарегистрирована на сайте.</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- поделиться новостью в соцсетях -->
            <like-appeal class="content-social" />
        </section>
        <section class="transparent-container">
            <h4>Похожие обращения граждан</h4>
            <tricolor-line class="tricolor_line"/>
            <div class="contain">
                <appeal-card :key="`link_1`" :card="getAllAppeal[0]" :listView="'btnBlock'" class="containet_block"/>
                <appeal-card :key="`link_2`" :card="getAllAppeal[1]" :listView="'btnBlock'" class="containet_block"/>
                <appeal-card :key="`link_3`" :card="getAllAppeal[2]" :listView="'btnBlock'" class="containet_block"/>
            </div>
            <router-link to="/appeal">Посмотреть все</router-link>
        </section>
        <modal-appeal v-if="modal" @close="modal = false"/>
    </div>
</template>

<script>
import LikeAppeal from '@/components/appeal/LikeAppeal'
import BreadCrumb from '@/components/BreadCrumb'
import SlideAppeal from '@/components/appeal/SlideAppeal'
import Documents from '@/components/appeal/Documents'
import MapForm from '@/components/appeal/MapForm'
import TricolorLine from '@/components/TricolorLine'
import AppealCard from '@/components/appeal/AppealCard'
import ModalAppeal from '@/components/appeal/ModalAppeal'

export default {
  name: 'AppealDetail',
  components: {
    'bread-crumb': BreadCrumb,
    'slide-appeal': SlideAppeal,
    'attached-documents': Documents,
    'map-form': MapForm,
    'like-appeal': LikeAppeal,
    'tricolor-line': TricolorLine,
    'appeal-card': AppealCard,
    'modal-appeal': ModalAppeal
  },
  data () {
    return {
      hiddenText: true,
      showText: false,
      modal: false,
      getTempIdNews: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Обращения граждан'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('getSpecificAppeal', this.$route.params.id)
    console.log(this.$route)
  },
  watch: {
    getAppeal (val) {
      this.getTempIdNews = this.getAllAppeal.findIndex(f => f.id === this.$route.params.id)
    }
  },
  computed: {
    getAllAppeal () {
      console.log(this.$store.getters.getSpecificAppeal)
      return this.$store.getters.getSpecificAppeal
    },
    getAppeal () {
      const content = this.$store.getters.getAppeal.appeal
      return content ? content[this.getTempIdNews] : ''
    },
    getImages () {
      const content = this.getAppeal
      return content.photo ? content.photo : []
    }
  },
  methods: {
    sliceText (text) {
      if (!this.showText) {
        if (text.length > 400) {
          var str = text.slice(0, 400)
          var a = str.split(' ')
          a.splice(a.length - 1, 1)
          return a.join(' ') + ' ... '
        } else {
          this.hiddenText = false
          return text
        }
      } else return text
    },
    clickText () {
      this.showText = !this.showText
      this.sliceText(this.getAppeal.subtitle)
    }
  }
}
</script>
<style lang="scss" scoped>
.title_heder_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: .06rem solid #D5D6D9;
    h5 {
        font-weight: 600;
        font-size: 1.38rem;
        line-height: 1.75rem;
        color: #1F3245;
    }
    span{
        font-weight: 600;
        font-size: 1rem;
        line-height: 2rem;
        color: #1F3245;
    }
}
.detail__status{
  display: flex;
  margin: 1.69rem 0 1.68rem 0;
  .detail__type{
    display: flex;
    align-items: center;
    p{
      font-size: .875rem;
      line-height: 1.38rem;
      color: #246CB7;
    }
    figure{
      width: 1.27rem;
      height: 1.063rem;
      margin-right: .69rem;
      background-repeat: no-repeat;
    }
    &:nth-child(2){
      margin-left: 1.813rem;
      figure{
        background: url('../../assets/icons/public.svg') center / contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__block{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: auto;
    p{
      font-size: .81rem;
      line-height: 1.25rem;
      color: #5E686C;
      opacity: 0.8;
    }
    span{
      width: .56rem;
      height: .56rem;
      border-radius: 50%;
      padding: .28rem;
    }
    .red_point{
      background: #EB494E;
      margin-left: .44rem;
    }
    .blue_point{
      background: #246CB7;
      margin-right: .44rem;
    }
    .red-line{
      border-top: 0.125rem solid #EB494E;
      width: calc(50% - 30%);
    }
    .blue-line{
      border-top: 0.125rem solid #246CB7;
      width: calc(50% - 20%);
    }
  }
}
.content{
  width: 100%;
  display: flex;
    &__info_appeap{
      width: 40.94rem;
      h6{
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1F3245;
        margin-bottom: .94rem;
      }
    }
    &__description{
      font-size: .88rem;
      line-height: 1.75rem;
      color: #1F3245;
      margin-bottom: 2.18rem;
      span{
        font-weight: 600;
        color: #246CB7;
        cursor: pointer;
      }
    }
    &__status{
      flex: auto;
      margin-left: 2.19rem;
      &_history{
          display: flex;
          figure{
            position: relative;
            top: 1.06rem;
            width: 1rem;
            background: url('../../assets/icons/status_archived.svg') center top no-repeat;
            p {
              border-left: .125rem dashed #DDEDFE;
              box-sizing: border-box;
              border-radius: .063rem;
              margin-top: 1rem;
              height: 100%;
              margin-left: .468rem;
            }
          }
          div{
            position: relative;
            padding: .94rem 1.875rem 1.5rem 1.875rem;
            margin-bottom: 1.88rem;
            margin-left: 1.25rem;
            background: #FFFFFF;
            border-radius: .19rem;
            filter: drop-shadow(0px 0.62rem 2.06rem rgba(29, 79, 132, 0.18));

            .status{
              &_date{
                font-weight: 600;
                font-size: .75rem;
                line-height: 1.38rem;
                color: #9D9D9D;
              }
              &_title{
                font-weight: bold;
                font-size: .88rem;
                line-height: 2.5rem;
                color: #1F3245;
              }
              &_subtitle{
                margin-top: .63rem;
                font-size: .81rem;
                line-height: 1.38rem;
                color: #1F3245;

                span{
                  font-weight: bold;
                  color: #003E78;
                }
              }
            }
            button{
              margin: 1.19rem 0 .38rem 0;
              padding: 0.5rem 2.75rem .44rem 2.75rem;
              border: 0;
              background: #246CB7;
              border-radius: .19rem;

              font-weight: 600;
              font-size: .88rem;
              line-height: 1.25rem;
              color: #ffffff;

              cursor: pointer;
            }
          }
          div:before{
            content: '';
            width: .87rem;
            height: .87rem;
            position: absolute;
            top: 1.06rem;
            left: -0.43rem;
            transform: rotate(45deg);
            background: #FFFFFF;
            filter: drop-shadow(0px 0.62rem 2.06rem rgba(29, 79, 132, 0.18));
          }

      }
      &_history:first-child{
          figure{
            background: url('../../assets/icons/status_active.svg') center top no-repeat;
          }
      }
      &_history:last-child{
          figure{
            height: 100%;
          }
      }
    }
    &__location{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
        font-size: .82rem;
        line-height: 1.125rem;
        color: #246CB7;
        margin-bottom: .94rem;
      }
      p:before{
        content: '';
        width: .75rem;
        height: 1rem;
        padding: .004rem .375rem;
        background: url('../../assets/icons/location.svg') center center no-repeat;
        background-size: contain;
        margin-right: .44rem;
      }
    }
    .map{
        margin: .63rem 0 2.75rem 0;
        width: 100%;
        height: 15.75rem;
    }
    &__participant{
      display: flex;
      margin: .63rem 0 2.5rem 0;
      figure{
        margin-right: .63rem;
        width: 2.813rem;
        height: 2.813rem;
        border-radius: 50%;
        background: #9D9D9D;
        box-shadow: 0px .25rem .25rem rgba(0, 0, 0, 0.25);
      }
      div{
        span{
          font-size: .75rem;
          line-height: 1.38rem;
          color: rgba(23, 23, 23, 0.8);
          opacity: 0.8;
        }
        p{
          font-weight: 600;
          font-size: .813rem;
          line-height: 1.25rem;
          color: #1F3245;
        }
      }
    }
    &-social {
        border-top: .063rem solid #D5D6D9;
        padding-top: 2.5rem;
    }
}
.transparent-container{
    width: 90rem;
    margin: 0 auto;
    padding: 3.1rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4{
      width: 100%;
      font-weight: 600;
      font-size: 1.38rem;
      line-height: 1.5rem;
      color: #1F3245;
    }
    .tricolor_line{
      margin: 1.563rem 0 2.44rem 0;
      width: 100%;
    }
    .contain{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.06rem;
        .containet_block{
            margin: 0 1.06rem 1.87rem 1.06rem;
            height: 18.9375rem;
            width: calc(33.333% - 2.125rem);
        }
    }
    a{
      background: #246CB7;
      border-radius: .19rem;
      margin-top: 4.69rem;
      padding: .5rem 0 .44rem 0;
      width: 10rem;

      font-weight: 600;
      font-size: .875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #FFFFFF;
    }
}
@media screen and (max-width: 768px){}
@media screen and (max-width: 420px){}
</style>
